import React from "react"
import { Link } from "gatsby"

const Footer = () => (
  <footer
    style={{
      height: "100px",
      position: "absolute",
      bottom: "0",
      width: "100%",
      background: "#EFEEEA",
    }}
  >
    <div
      style={{
        margin: `0 auto`,
        paddingTop: "2em",
        maxWidth: 960,
      }}
    >
      <div className="row">
        <div className="col">
          © {new Date().getFullYear()}, Built with
          {` `}
          <a href="https://www.gatsbyjs.org">Gatsby</a>
        </div>
        <div className="col">
          <Link className="text-muted mr-4" to="/archive">
            Архив
          </Link>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
