import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { FaArrowCircleLeft } from "react-icons/all"
import AniLink from "gatsby-plugin-transition-link/AniLink"

const Header = ({ siteTitle, backLink }) => (
  <header
    className="mb-md-2"
    style={{
      background: `#EFEEEA`,
    }}
  >
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 960,
        padding: `1.45rem 1.0875rem`,
      }}
      className="text-center"
    >
      {backLink && (
        <AniLink
          swipe
          direction="right"
          to="/"
          style={{ marginLeft: "-20px", marginTop: "10px" }}
          className="d-none"
        >
          <FaArrowCircleLeft />
        </AniLink>
      )}
      <h1 className="my-0 logo">
        <Link
          to="/"
          style={{
            color: `#333`,
            textDecoration: `none`,
          }}
        >
          {siteTitle}
        </Link>
      </h1>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
